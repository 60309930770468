import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass';
import { WebshopNewLayout } from '../../../layouts/WebshopNew.layout';
import { useRecoilValue } from 'recoil';
import { navigateTo } from '../../../components/functional/Navigate';
// import { navigate } from 'gatsby';
import { purchasePackage, successfullPurchase } from '../../../shared-state/main';
import { weAreRunningInBrowser } from '../../../utils/weAreRunningInBrowser';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';
import { COUNTRY } from '../../../configs/env';
import StoreBadgeIcons from '../../../components/layout/StoreBadgeIcons/StoreBadgeIcons';

const PurchaseSuccessfullPage = () => {
  const { t, i18n } = useTranslation();

  const user = useRecoilValue(successfullPurchase);
  const bundleDetails = useRecoilValue(purchasePackage);

  const LANG = i18n.language;

  const metaOgImg = `/img/meta-img/road-assistance-meta-og-2-0-new.jpeg`;

  const meta = {
    title: t('meta_RoadsideAssistanceTitle'),
    description: t('meta_RoadsideAssistanceDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  if (!user.email) {
    navigateTo(t('link_home'));
  }

  useEffect(() => {
    if (weAreRunningInBrowser()) {
      gTagDataLayerPush({
        event: 'web_shop_finished',
        price: bundleDetails.packagePrice,
        country: COUNTRY,
        bundleKeyName: bundleDetails.bundleKeyName,
      });
    }
  }, []);

  return (
    <WebshopNewLayout meta={meta} og={og}>
      <Container style={{ padding: '60px 0', backgroundColor: 'white' }}>
        <div style={{}}>
          <img src="/img/success-payment.png" />
          <Text style={{ margin: '50px 0' }}>
            {t('successfullPurchaseEmail')} {user.email}.
            <br />
            <br /> {t('successfullPurchasePhoneNumberPartOne')} {user.mobileNumber}
            {t('successfullPurchasePhoneNumberPartTwo')}
            <br /> {t('successfullPurchaseAppText')}
            <br />
            <br /> {t('successfullPurchaseThankYouText')}
          </Text>
          <StoreBadgeIcons />
        </div>
      </Container>
    </WebshopNewLayout>
  );
};

export default PurchaseSuccessfullPage;
